import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo : false,
    userShops : false,
    services : [
        "users",
        "shops",
        "categories",
    ],
    storeCache : {
      "categories" : null,
      "productOnMain" : null,
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
