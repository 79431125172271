import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/bundle.css'
import {Manager} from "@/helper/api";
let app = createApp(App)
import { initSwipeBehavior } from '@telegram-apps/sdk';

import * as Sentry from "@sentry/vue";
try {
    const [swipeBehavior] = initSwipeBehavior();
    swipeBehavior.disableVerticalSwipe();
}
catch (e) {
    console.log(e)
}


app.config.globalProperties.$fixPrice = (num)=>{
    return new Intl.NumberFormat("ru").format(num)
}

app.config.globalProperties.$api = Manager;
app.config.globalProperties.$cdn = "https://cdn.tagito.store/";
if(process.env.NODE_ENV === 'production'){
    Sentry.init({
        app,
        dsn: "https://10facfe8a8a0278c9a3ed8e0e1cf3357@o4507808435208192.ingest.de.sentry.io/4507808452116560",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/app\.\/tagito\.store/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

app.use(store).use(router).mount('#app')

