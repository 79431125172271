<script>
export default {
  name: "ShopInList",
  props : {
    data : {
      type : Object
    },
    skeleton : {
      type : Boolean,
      default : false,
    }
  },
  methods : {
    goToShop(){
      if(this.data._id){
        this.$router.push('/shops/view/'+this.data._id + '?from=/shops/manage');
      }
    }
  }
}
</script>

<template>
  <div @click="goToShop()" :class="(skeleton) ? 'skeleton shop-in-list w-full animate-pulse' : 'shop-in-list'">
    <div :class="(skeleton) ? 'skeleton avatar' : 'avatar'" :style="`background-image: url(${$cdn}shops/${data.logo}.png)`"></div>
    <span :class="(skeleton) ? 'skeleton-text' : ''">{{data.name}}</span>
  </div>
</template>

<style scoped>
.avatar{
  height: 35px;
  width: 35px;
  background-position: center center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border-radius: 50%;
  background: gray;
}
.shop-in-list{
  background: #fff;
  display: flex;
  box-shadow: 0px 0px 10px 0px #0000000D;
  font-size: 15px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 15px;
}

.skeleton.avatar{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #bbbbbb;
}
.skeleton.shop-in-list{
  background: #d2d2d2;
  display: flex;
  box-shadow: 0px 0px 10px 0px #0000000D;
  font-size: 15px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 15px;
}
.skeleton-text{
  padding: 5px 40px;
  background: #bbbbbb;
  border-radius: 24px;
}
</style>