<script>
export default {
  name: "ShopAvatar",
  props : {
    skeleton : {
      type : Boolean
    },
    file : {
      type : String
    }
  }
}
</script>

<template>
  <div :class="(skeleton) ? 'a-skeleton shop-include' : 'shop-include avatar'" :style="`background-image: url(${$cdn}shops/${file}.png)`">

  </div>
</template>

<style scoped>
  .shop-include{
    height: 160px;
    width: 160px;
    border-radius: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

</style>