<script>
import Navigation from "@/components/Navigation.vue";
import PageBadge from "@/components/PageBadge.vue";
import CatsWrapper from "@/components/Home/CatsWrapper.vue";


export default {
  name: "HomePage",
  components:{
    CatsWrapper,
    PageBadge,
    Navigation
  },
  data(){
    return {
      loadingProds : true,
      products : [],
    }
  },
  async mounted(){

  }
}
</script>

<template>
  <div class="page">
    <PageBadge text="Рекомендации"></PageBadge>
    <CatsWrapper></CatsWrapper>
    <Navigation></Navigation>
  </div>
</template>

<style scoped>
.fake-product{
  height: 200px;
  border-radius: 20px;
  width: 170px;
}
</style>