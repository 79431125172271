<script>
import PageBadge from "@/components/PageBadge.vue";
import NavigationComponent from "@/components/Navigation.vue";
import ProductInShop from "@/components/Products/ProductInShop.vue";

export default {
  name: "ViewCategory",
  data(){
    return {
      loadingProds : true,
      products : [],
    }
  },
  async mounted() {
      this.loadingProds = true
      let products = await this.$api.get('categories/' + this.$route.params.id +'/products?date=1');
      this.$store.state.storeCache.productOnMain = products;
      this.products = products;
      this.loadingProds = false;

  },
  components: {ProductInShop, NavigationComponent, PageBadge, }
}
</script>

<template>
  <div class="page">
    <PageBadge :text="$route.query.title" back="1"></PageBadge>
    <div class="product-container" v-if="!loadingProds">
      <ProductInShop :data="product" :key="product" v-for="product of products"></ProductInShop>
    </div>
    <div class="product-container" v-if="loadingProds">
      <div class="fake-product a-skeleton" v-for="i in 10" :key="i"></div>
    </div>
  </div>

  <NavigationComponent></NavigationComponent>
</template>

<style scoped>
.fake-product{
  height: 200px;
  border-radius: 20px;
  width: 120px;
}
</style>