<script>
export default {
  name: "PageBadge",
  props : {
    text : {
      type : String,
    },
    back : {
      type : String,
      default : ""
    },
    variant : {
      type : String,
      default : 'default'
    }
  }
}
</script>

<template>

  <div class="badge-container" v-if="variant === 'default'">
    <span class="back-btn" @click="$router.go(-1)" v-if="back != ''">
      &leftarrow;
    </span>
    <span class="page-badge">
      {{text}}
    </span>
  </div>
  <div class="badge-container-header" v-if="variant === 'header'">
    <div class="back-btn" @click="$router.go(-1)" v-if="back != ''">
      <img src="@/assets/icons/arrow_left.svg" class="back_icon" height="32" width="32" alt="">
    </div>
    <span class="page-badge-header">
      {{text}}
    </span>
  </div>

</template>

<style scoped>
  .page-badge{
    background: linear-gradient(90deg, #0181A8 0%, #02AEBA 100%);
    padding: 10px 20px;
    color: #fff;
    font-size: 13px;
    border-radius: 30px;
    text-align: center;
  }
  .back_icon{
    height: 16px;
    width: 16px;

  }
  span{
    font-size: 15px;
  }
  .badge-container{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    gap: 10px;
    margin-bottom: 20px;
  }
  .badge-container-header{
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 0 5%;
    background: #fff;
    padding: 10px;
    gap: 10px;
    margin-bottom: 20px;
  }
  .badge-container .back-btn{
    color: #fff;
    top: 10px;
  }
  .back-btn{
    flex: none;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 5px;
    height: 34px;
    background: linear-gradient(90deg, #0181A8 0%, #02AEBA 100%);
    border-radius: 50%;

  }
</style>