<script>
import NavigationComponent from "@/components/Navigation.vue";
import {initPopup, initUtils} from '@telegram-apps/sdk';
import {Pagination} from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import ButtonUI from "@/components/Button.vue";


export default {
  name: "ProductView",
  components: {ButtonUI, NavigationComponent, Swiper, SwiperSlide},
  data(){
    return {
      utils : initUtils(),
      popup : initPopup(),
      seller : {},
      shop : {},
      prodData : {},
      loading: true,
    }
  },
  async mounted(){
    window.scroll(0,0)
    let product = await this.$api.get('products/' + this.$route.params.id)
    if(!product.name){
      this.$router.push('/home');

    }
    else{
      this.prodData = product;
      this.seller = await this.$api.get('users/' + product.seller);
      this.shop = await this.$api.get('shops/view/' + product.shop);

    }
    this.loading = false;
  },
  methods : {
    async removeProduct(){
      this.popup.open({
        title: 'Подтвердите действие',
        message: 'Вы уверены, что хотите удалить товар?',
        buttons: [{ id: 'cancel', type: 'default', text: 'Отменить' },{ id: 'remove', type: 'destructive', text: 'Удалить товар' }],
      }).then( async buttonId => {

                if(buttonId === "remove"){
                  let product = await this.$api.post('products/remove/' + this.$route.params.id)
                  if(product.status === 200){
                    this.$router.push('/home');
                    alert("Вы успешно удалили товар")
                  }
                }
          });
    },
    editProduct(){
      this.$router.push('/products/edit/' + this.$route.params.id);
    },
    getContact(){
      this.utils.openTelegramLink('https://t.me/' + this.seller.username);
    },
    sharePage(){
      console.log("share")
      this.utils.shareURL('t.me/Tagito_bot/Tagito?startapp=place_product' + this.$route.params.id, 'Посмотри, классный товар нашел на Tagito!');
    },
    openShop(){
      this.$router.push('/shops/view/' + this.shop._id + '?from=' + this.$route.path);
    }
  },
  setup() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '" index="'+index+'"></span>';
        },
      },
      modules: [Pagination],
    };
  },

}
</script>

<template>
  <div class="loading-block" v-if="loading">
    <div class="logotype">
      <video  playsinline autoplay width="100" muted  loop>
        <source src="@/assets/loading.webm" type="video/webm">
      </video>
    </div>
    <p>Загрузка контента</p>
  </div>
  <div class="product-view page" v-if="!loading">
    <div class="product-header">
      <div class="mini-button" @click="$router.go(-1)">
        <img src="@/assets/icons/arrow_left.svg" alt="">
      </div>
      <div class="mini-button" @click="sharePage()">
        <img src="@/assets/icons/share.svg" alt="">
      </div>

    </div>
    <div class="product-image">
      <swiper
          :pagination="pagination" :modules="modules"
      >
        <swiper-slide v-for="photo of prodData.photos" :key="photo"><div class="product-image-source" :style="`background-image: url(${$cdn}products/${photo}.png)`"></div></swiper-slide>
      </swiper>
    </div>
    <div class="container">
      <div class="info">
        <span class="price">{{$fixPrice(prodData.price)}} ₽</span>
        <span class="name">{{ prodData.name }}</span>
      </div>
      <div class="actions">
        <ButtonUI @click="getContact">Написать продавцу</ButtonUI>
      </div>
      <div class="divider"  v-if="seller.id === $store.state.userInfo.id"></div>
      <div class="actions" v-if="seller.id === $store.state.userInfo.id">
        <ButtonUI @click="editProduct()">Изменить товар</ButtonUI>
        <ButtonUI variant="minimal-red" @click="removeProduct()">Удалить товар</ButtonUI>
      </div>
      <div class="divider"></div>
      <div class="shopCard">
        <div class="shopInfo">
          <div class="shopAvatar" :style="`background-image: url(${$cdn}shops/${shop.logo}.png)`"></div>
          <span class="shopName">{{shop.name}}</span>
        </div>
        <div class="shopAction">
          <span @click="openShop">Смотреть все</span>
        </div>
      </div>
      <div class="divider"></div>
      <div class="info-block">
        <h2>Описание</h2>
        <p>{{prodData.description}}</p>
      </div>

    </div>
  </div>
  <NavigationComponent></NavigationComponent>
</template>

<style scoped>
.loading-block{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column; gap: 20px;
  width: 100vw;
}
.logotype{
  border-radius: 15px;
  overflow: hidden;
  height: max-content;
  width: max-content;
}
video{
  width: 110px;
  height: 110px;
}
.product-view{
  width: 100%;
  min-height: 100vh;
  background: #fff;
}
.shopCard{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  background: #F2F2F2;
}
.shopInfo{
  display: flex;
  align-items: center;
  gap: 10px;
}
.shopAvatar{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.shopAction span{
  color: #0181A8;
  font-size: 13px;
}
h2{
  font-size: 20px;
  color: #4F4F4F;
  font-weight: 500;
}
.info-block p{
  color: #4F4F4FB2;
  font-size: 13px;
  white-space: pre-wrap;
  line-height: 15.85px;
}
.shopName{
  font-size: 18px;
  color: #4F4F4F;
  font-weight: 500;
}
.container{
  display: flex;

  flex-direction: column;
  gap: 10px;
}
.info{
  display: flex;
  flex-direction: column;
}
.actions{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.product-header{
  display: flex;
  padding: 10px;
  justify-content: space-between;
}
.name{
  font-size: 18px;
  color: #4F4F4F;
  font-weight: 500;
}
.product-image{
  width: 100vw;
  height: 100vw;
  position: relative;
}
.product-image-source{
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.price{
  font-size: 24px;
  background: linear-gradient(90deg, #0181A8 0%, #02AEBA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}
</style>