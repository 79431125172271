<script>
import PageBadge from "@/components/PageBadge.vue";
import Navigation from "@/components/Navigation.vue";
import ShopInList from "@/components/Shops/ShopInList.vue";
import ButtonUI from "@/components/Button.vue";

export default {
  name: "ListShops",
  components: {ButtonUI, ShopInList, Navigation, PageBadge},
  data(){
    return {
      loading : true,
      shops : null
    }
  },
  async mounted() {
    this.loading = true;
    if(this.$store.state.userShops.length > 0 && !this.$store.state.needToRefreshData){
      this.loading = false;
      this.shops = this.$store.state.userShops;
      this.$store.state.needToRefreshData = false;
    }
    else{
      let data = await this.$api.get('shops/me');
      this.loading = false;
      this.shops = data;
    }

  },
  methods : {
    tryCreateShop(){
      if(this.$store.state.userInfo.username == ''){
        alert("У вас не установлен никнейм")
      }
      else{
        this.$router.push('/shops/create/first')
      }
    }
  }
}
</script>

<template>
  <div class="page">
    <PageBadge text="Выберите магазин"></PageBadge>
    <div class="container">

        <input type="text" class="ui-input w-full" placeholder="Поиск">
        <div class="shops-list" v-if="shops != null">
          <ShopInList v-for="shop in shops" :data="shop" :key="shop"></ShopInList>
        </div>
        <div class="shops-list" v-if="loading">
          <ShopInList v-for="shop in 4" :skeleton="true" :data="{name:''}" :key="shop"></ShopInList>
        </div>


    </div>
    <Navigation>
      <ButtonUI class="w-full" @click="tryCreateShop()">Добавить магазин</ButtonUI>
    </Navigation>
  </div>
</template>

<style scoped>

.shops-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

</style>