<script>

import NavigationComponent from "@/components/Navigation.vue";
import ShopAvatar from "@/components/Shops/Includes/ShopAvatar.vue";
import ShopName from "@/components/Shops/Includes/ShopName.vue";
import ButtonUI from "@/components/Button.vue";
import ShopSlogan from "@/components/Shops/Includes/ShopSlogan.vue";
import ProductInShop from "@/components/Products/ProductInShop.vue";
import {initUtils} from "@telegram-apps/sdk";

export default {
  name: "ShopsView",
  components: {ProductInShop, ShopSlogan, ButtonUI, ShopName, ShopAvatar, NavigationComponent},
  data(){
    return {
      loading: true,
      utils : initUtils(),
      shopData : {},
      products : [],
    }
  },
  async mounted() {
    let shop = await this.$api.get('shops/view/' + this.$route.params.id)
    let products;
    if(shop.name){
      products = await this.$api.get('shops/view/' + this.$route.params.id + '/products');
    }
    else{
      this.$router.push('/home');
    }
    this.products = products;
    this.shopData = shop;
    this.loading = false;
  },
  methods : {
    share(){
      this.utils.shareURL('t.me/Tagito_bot/Tagito?startapp=place_shop' + this.$route.params.id, `Смотри, нашел прекрасный магазин - ${this.shopData.name} на маркетплейсе Tagito. Загляни обязательно`);
    }
  }
}
</script>

<template>
<div class="page">
  <div class="container">
    <div class="shop-header">
      <div class="mini-button" @click="$router.go(-1)">
        <img src="@/assets/icons/arrow_left.svg" alt="">
      </div>
      <div class="right_header">
        <div class="mini-button" @click="share()">
          <img src="@/assets/icons/share.svg" alt="">
        </div>
        <div class="mini-button" @click="$router.push(`/shops/edit/${$route.params.id}`)"  v-if="Number(shopData.owner) == Number($store.state.userInfo.id)">
          <img src="@/assets/icons/settings.svg" alt="">
        </div>
      </div>

    </div>
    <div class="layout">
      <div class="header">
        <ShopAvatar :skeleton="loading" :file="shopData.logo"></ShopAvatar>
        <ShopName :skeleton="loading" :text="shopData.name"></ShopName>
        <ShopSlogan :skeleton="loading" :text="shopData.slogan"></ShopSlogan>
      </div>

    </div>

  </div>
  <div class="content">
    <div class="product-container">
      <ProductInShop v-for="product of products" :data="product" :key="product._id"></ProductInShop>
    </div>
  </div>
</div>
  <NavigationComponent v-if="Number(shopData.owner) == Number($store.state.userInfo.id)">
    <ButtonUI class="w-full" @click="$router.push('/products/create/first?cat=' + shopData.category + '&shop=' + shopData._id + '&from=' + $route.query.from)">Добавить товар</ButtonUI>
  </NavigationComponent>
  <NavigationComponent v-else></NavigationComponent>
</template>

<style scoped>
  .shop-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .right_header{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .content{
    margin-top: 40px;
  }
  .layout{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .header{
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .mini-button{
    width: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    background: #00000080;
  }
</style>