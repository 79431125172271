<script>
import PageBadge from "@/components/PageBadge.vue";
import ButtonUI from "@/components/Button.vue";
import TextareaComponent from "@/components/Textarea.vue";
import FileUploader from "@/components/FileUploader.vue";

export default {
  name: "BugReport",
  components: {FileUploader, TextareaComponent, ButtonUI, PageBadge},
  data(){
    return {
      files : [],
      description : ""
    }
  },
  methods:{
    hideMobileKeyboard(ev){
      ev.target.blur();
    },
    removePhoto(photo){
      this.files.splice(this.files.indexOf(photo),1);
    },
    toFile(ev){
      this.files.push({f : ev[0], s : URL.createObjectURL(ev[0])});
    },
  }
}
</script>

<template>
  <page-badge back="1" text="Репорт ошибки" variant="header"></page-badge>
  <div class="page">
    <div class="container">
    <form action="?" class=" flex flex-col gap-2 " @submit.prevent @click="void(1)">
      <label for="">
        <span class="label-input">Описание проблемы</span>
        <TextareaComponent @enter="e => description = e" maxlength="300" placeholder="Введите описание до 300 символов"></TextareaComponent>
      </label>
      <span class="label-input">Скриншоты или видео (до 3 файлов)*</span>
      <div class="images">
        <div class="image" v-for="photo in files" :key="photo">
          <img :src="photo.s" alt="">
          <div class="remove" @click="removePhoto(photo)">
            <img src="@/assets/icons/remove_photo.svg" alt="">
          </div>
        </div>
        <FileUploader v-if="files.length < 7" nopreview @file="toFile"></FileUploader>
      </div>

      <ButtonUI class="mt-2" @click="create()">Отправить репорт</ButtonUI>
    </form>
    </div>
  </div>
</template>

<style scoped>

</style>