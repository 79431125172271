<script>
import { initPopup } from '@telegram-apps/sdk';
import { initUtils } from '@telegram-apps/sdk';
export default {
  name: "NavigationComponent",
  data(){
    return {
      utils : initUtils(),
      popup : initPopup(),
      showMultiTool : false,
    }
  },
  mounted() {
    if(Object.keys(this.$slots).length > 0){
      this.showMultiTool = true;
    }
  },
  methods : {
    createShop(){
      if(this.$store.state.userInfo.username == ""){

        this.popup.open({
              title: 'Тут такое дело...',
              message: 'Чтобы создать свой магазин и разместить в нем товары, нужно завести @username в настройках Telegram профиля.',
              buttons: [{id: "go-settings", type : "default", text : "Понятно"}],
            })
      }
      else{
        if(this.$store.state.userShops.length > 0){
          this.$router.push('/shops/manage');
        }
        else{
          this.$router.push('/shops/create/first');
        }
      }
    }
  }
}
</script>

<template>
  <div class="nav-container">
    <div class="nav-action" v-show="showMultiTool">
      <slot></slot>
    </div>

    <div class="navigation">
      <router-link :active-class="'nav-item-active'" to="/home" class="nav-item">
        <div class="icon">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0986 2.7515C12.6299 2.28287 11.8701 2.28287 11.4015 2.7515L3.0015 11.1515C2.53287 11.6201 2.53287 12.3799 3.0015 12.8486C3.47013 13.3172 4.22992 13.3172 4.69855 12.8486L5.05002 12.4971V20.4C5.05002 21.0628 5.58728 21.6 6.25002 21.6H8.65002C9.31277 21.6 9.85002 21.0628 9.85002 20.4V18C9.85002 17.3373 10.3873 16.8 11.05 16.8H13.45C14.1128 16.8 14.65 17.3373 14.65 18V20.4C14.65 21.0628 15.1873 21.6 15.85 21.6H18.25C18.9128 21.6 19.45 21.0628 19.45 20.4V12.4971L19.8015 12.8486C20.2701 13.3172 21.0299 13.3172 21.4986 12.8486C21.9672 12.3799 21.9672 11.6201 21.4986 11.1515L13.0986 2.7515Z" fill="#BCBCBC"/>
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="12.25" y2="21.5999" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0181A8"/>
                <stop offset="1" stop-color="#02AEBA"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <span>Главная</span>
      </router-link>
      <router-link :active-class="'nav-item-active'" to="/favorites" class="nav-item">
        <div class="icon">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.55591 6.20594C6.43043 4.33142 9.46962 4.33142 11.3441 6.20594L12.75 7.61182L14.1559 6.20594C16.0304 4.33142 19.0696 4.33142 20.9441 6.20594C22.8187 8.08045 22.8187 11.1196 20.9441 12.9942L12.75 21.1883L4.55591 12.9942C2.6814 11.1196 2.6814 8.08045 4.55591 6.20594Z" fill="#BCBCBC"/>
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="12.25" y2="21.5999" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0181A8"/>
                <stop offset="1" stop-color="#02AEBA"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <span>Избранное</span>
      </router-link>

      <div @click="createShop" class="nav-item plus"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_635_4205)">
          <path d="M6.25 15H23.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15 23.75V6.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_635_4205">
            <rect width="30" height="30" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      </div>
      <router-link to="/cart" class="nav-item">
        <div class="icon">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.915 2.33002C15.0929 2.24111 15.2987 2.22645 15.4874 2.28927C15.676 2.35209 15.832 2.48724 15.921 2.66502L18.122 7.06702C19.475 7.17102 20.324 7.43703 20.872 8.11403C21.308 8.65303 21.448 9.32302 21.397 10.25H3.10301C3.05201 9.32302 3.19301 8.65303 3.62801 8.11403C4.17601 7.43603 5.02501 7.17102 6.37801 7.06702L8.57901 2.66502C8.66879 2.48866 8.82463 2.35492 9.01258 2.29295C9.20053 2.23099 9.40535 2.24582 9.58242 2.33422C9.75948 2.42262 9.89443 2.57742 9.95785 2.76488C10.0213 2.95235 10.008 3.15728 9.92101 3.33502L8.08601 7.00502C8.56901 7.00002 9.09601 7.00002 9.67201 7.00002H14.828C15.404 7.00002 15.931 7.00002 16.414 7.00502L14.579 3.33502C14.4902 3.15706 14.4757 2.9511 14.5387 2.76244C14.6017 2.57378 14.7371 2.41888 14.915 2.33002Z" fill="#BCBCBC"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.80498 14.257C3.62052 13.4243 3.45049 12.5885 3.29498 11.75H21.205C21.0494 12.5885 20.8793 13.4243 20.695 14.257L20.266 16.257C19.779 18.53 19.536 19.666 18.711 20.333C17.886 21 16.724 21 14.4 21H10.1C7.77598 21 6.61398 21 5.78998 20.333C4.96398 19.666 4.71998 18.53 4.23398 16.257L3.80498 14.257ZM10.25 13.25C10.0511 13.25 9.86031 13.329 9.71965 13.4697C9.579 13.6103 9.49998 13.8011 9.49998 14C9.49998 14.1989 9.579 14.3897 9.71965 14.5303C9.86031 14.671 10.0511 14.75 10.25 14.75H14.25C14.4489 14.75 14.6397 14.671 14.7803 14.5303C14.921 14.3897 15 14.1989 15 14C15 13.8011 14.921 13.6103 14.7803 13.4697C14.6397 13.329 14.4489 13.25 14.25 13.25H10.25Z" fill="#BCBCBC"/>
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="12.25" y2="21.5999" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0181A8"/>
                <stop offset="1" stop-color="#02AEBA"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <span>Корзина</span>
      </router-link>
      <router-link to="/profile" :active-class="'nav-item-active'" class="nav-item">
        <div class="icon">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.75 12C14.1424 12 15.4777 11.4469 16.4623 10.4623C17.4469 9.47774 18 8.14239 18 6.75C18 5.35761 17.4469 4.02226 16.4623 3.03769C15.4777 2.05312 14.1424 1.5 12.75 1.5C11.3576 1.5 10.0223 2.05312 9.03769 3.03769C8.05312 4.02226 7.5 5.35761 7.5 6.75C7.5 8.14239 8.05312 9.47774 9.03769 10.4623C10.0223 11.4469 11.3576 12 12.75 12ZM6.375 13.5C5.67881 13.5 5.01113 13.7766 4.51884 14.2688C4.02656 14.7611 3.75 15.4288 3.75 16.125V16.5C3.75 18.2948 4.89225 19.8127 6.51375 20.8447C8.14425 21.8827 10.3515 22.5 12.75 22.5C15.1485 22.5 17.355 21.8827 18.9862 20.8447C20.6077 19.8127 21.75 18.2948 21.75 16.5V16.125C21.75 15.4288 21.4734 14.7611 20.9812 14.2688C20.4889 13.7766 19.8212 13.5 19.125 13.5H6.375Z" fill="#BCBCBC"/>
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="12.25" y2="21.5999" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0181A8"/>
                <stop offset="1" stop-color="#02AEBA"/>
              </linearGradient>
            </defs>
          </svg>

        </div>
        <span>Профиль</span>
      </router-link>
    </div>
  </div>


</template>

<style scoped>
.nav-container{
  bottom: 0;
  position: fixed;
  z-index: 7;
  width: 100%;
}
.navigation{
  background: #fff;

  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 40px;
  position: absolute;
  bottom: 0;
  box-shadow: 0px 0px 15px 0px #0000001A;
  border-radius: 30px 30px 0px 0px;
  z-index: 2;
  width: 100%;
}
.plus{
  background: linear-gradient(180.58deg, #0181A8 0.51%, #02AEBA 99.5%);
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px #00000066;
  justify-content: center;
  align-items: center;

  display: flex;
  color: #fff;
  width: 50px;
}
.nav-item{
  display: flex;
  align-items: center;
  color: #BABABA;
  justify-content: center;
  flex-direction: column;
}
.nav-item-active{
  color: #0181A8;
}
.nav-action{
  background: rgba(227, 227, 227, 0.53);
  bottom: 0;
  padding: 20px 20px 170px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  z-index: 2;
  height: 100%;
  border-radius: 20px;
  position: absolute;
  width: 100%;
}
.nav-item-active svg path{
  fill: url(#gradient);

}
</style>