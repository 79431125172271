<script>
import Navigation from "@/components/Navigation.vue";
import PageBadge from "@/components/PageBadge.vue";
import CategoryInList from "@/components/Shops/CategoryInList.vue";

export default {
  name: "SelectCategory",
  components: {CategoryInList, PageBadge, Navigation},
  data(){
    return {
      loading : true,
      categories : [],
      slugsAvailable : [
          "desk",
          "digital",
          "buildings",
          "market",
          "jobs",
          "startups",
          "handmades"
      ]
    }
  },
  methods : {
    categorySelected(id){
      this.$router.push('/shops/create/second/?cat=' + id)
    },
  },
  async mounted() {
      if(this.$store.state.storeCache.categories == null){
        let categories = await this.$api.get('categories');
        for(let category of categories){
          if(category.parent === 'unset' && this.slugsAvailable.includes(category.slug)){
            this.categories.push(category);
          }
        }
        this.$store.state.storeCache.categories = this.categories;
      }
      else{
        this.categories = this.$store.state.storeCache.categories;
      }

      this.loading = false;
  }
}
</script>

<template>

    <PageBadge variant="header" text="Выбор категории"></PageBadge>

    <div class="container">
      <div class="categories-list" v-if="loading">
        <CategoryInList v-for="n in 5" :key="n" :skeleton="true" :data="{name:''}"></CategoryInList>
      </div>
      <div class="categories-list" v-if="!loading">
        <CategoryInList @click="categorySelected(n._id)" v-for="n in categories" :key="n" :data="n"></CategoryInList>
      </div>

    </div>
  <Navigation></Navigation>
</template>

<style scoped>

.categories-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
</style>