let axios = require('axios');
class ApiManager{
    constructor(){
        // Если в разработке то http, в продакшне - https
        this.ssl = (process.env.NODE_ENV == 'production') ? 'https://' : 'http://';
        // Если в разработке localhost, в продакшне - kennedy.tagito.store
        this.baseURL = (process.env.NODE_ENV == 'production') ? 'kennedy.tagito.store' : 'localhost:3035';
        this.requestsHistory = [];
        // базовый роут
        this.baseRoute = "/";
    }

    async get(route){

        try {
            if(this.requestsHistory.length > 0 && Date.now() - this.requestsHistory[this.requestsHistory.length - 1].timestamp < 100 && this.ssl + this.baseURL + this.baseRoute + route == this.requestsHistory[this.requestsHistory.length - 1].url){
                console.log(Date.now() - this.requestsHistory[this.requestsHistory.length - 1].timestamp,this.requestsHistory[this.requestsHistory.length - 1])
                return;
            }
            this.requestsHistory.push({
                timestamp : Date.now(),
                url : this.ssl + this.baseURL + this.baseRoute + route
            })
            let res = await axios.get(this.ssl + this.baseURL + this.baseRoute + route,{
                headers : {
                    "Authorization" : 'Bearer ' + localStorage.getItem('token')
                }
            })
            if(res.status == 408){
                return {error : "timeout"}
            }
            return res.data;
        } catch (error) {
            if(error.message == "Request failed with status code 408"){
                alert("Некоторые наши службы сейчас недоступны, мы уже работаем над этим")
                return {error : "timeout"}
            }
            else{
                return error;
            }

        }

    }
    async post(route,data){
        try {
            if(this.requestsHistory.length > 0 &&  Date.now() - this.requestsHistory[this.requestsHistory.length - 1].timestamp < 100 && this.ssl + this.baseURL + this.baseRoute + route == this.requestsHistory[this.requestsHistory.length - 1].url){
                console.log(Date.now() - this.requestsHistory[this.requestsHistory.length - 1].timestamp,this.requestsHistory[this.requestsHistory.length - 1])
                console.log("request spam")
                return {};
            }
            this.requestsHistory.push({
                timestamp : Date.now(),
                url : this.ssl + this.baseURL + this.baseRoute + route
            })
            let res = await axios.post(this.ssl + this.baseURL + this.baseRoute + route,data,{
                headers : {
                    Authorization : 'Bearer ' + localStorage.getItem('token')
                }
            })
            return res.data;
        } catch (error) {
            return error;
        }

    }
}

module.exports = {Manager : new ApiManager()};