<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
export default {
  name: "ProductInShop",
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return {
      fdata : null
    }
  },
  props : {
    data : {
      type : Object
    }
  },
  mounted() {
    this.fdata = this.data;
    let photos = [];
    for(let photo in this.fdata.photos){
      if(photo < 4){
        photos.push(this.fdata.photos[photo])
      }
      else{
        break;
      }
    }
    this.fdata.photos = photos;
  },
  methods : {
    openProduct(){
      this.$router.push('/products/view/' + this.data._id + '?from=' + this.$route.path);
    }
  },
  setup() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '" index="'+index+'"></span>';
        },
      },
      modules: [Pagination],
    };
  },
}
</script>

<template>
  <div class="product-shop" v-if="fdata != null">
    <div class="product-image" @click="openProduct">
      <swiper
          :pagination="pagination" :modules="modules"
      >
        <swiper-slide v-for="photo of fdata.photos" :key="photo">
          <div :class="`product-image-source`" :style="`background-image: url(${$cdn}products/${photo}.png)`"><span class="last-photo" v-if="(fdata.photos.indexOf(photo) == data.photos.length -1 && fdata.photos.indexOf(photo) != 0)"></span></div>
        </swiper-slide>
      </swiper>
    </div>
    <span class="price">{{$fixPrice(fdata.price)}}₽</span>
    <span class="name">{{fdata.name}}</span>
  </div>
</template>

<style scoped>
.product-shop{
  --s : 45vw;
  width: var(--s);
  display: flex;
  gap: 2.5px;
  flex-direction: column;
}
.name{
  color: #4F4F4F;
  font-size: 14px;
}
.price{
  font-size: 20px;
  background: linear-gradient(90deg, #0181A8 0%, #02AEBA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}
.product-image-source{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-position: center top;

}
.last-photo::before{
  content: 'Перейти к товару';
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width .3s linear 0s;
  position: absolute;
  top: 0;

  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.8);
}
.product-image{

  height: calc(var(--s) + 8vh);
  overflow: hidden;
  width: var(--s);
  position: relative;
  border-radius: 20px;
  background-color: #fff;
}
</style>