<script>
import Navigation from "@/components/Navigation.vue";
import PageBadge from "@/components/PageBadge.vue";
import CategoryInList from "@/components/Shops/CategoryInList.vue";

export default {
  name: "SelectCategoryInProducts",
  components: {CategoryInList, PageBadge, Navigation},
  data(){
    return {
      loading : true,
      categories : [],
    }
  },
  methods : {
    categorySelected(id){
      this.$router.push('/products/create/second/?parent=' + this.$route.query.cat + '&cat=' + id + '&shop=' + this.$route.query.shop + '&from=' + this.$route.query.from)
    },
  },
  async mounted() {
    let parent = this.$route.query.cat;


    let categories = await this.$api.get('categories');
    for(let category of categories){
      if(category._id === parent){
        for(let child of category.childs){
          this.categories.push(child);
        }
      }
    }



    this.loading = false;
  }
}
</script>

<template>

  <PageBadge :back="`/shops/view/${$route.query.shop}?from=${$route.query.from}`" variant="header" text="Выбор категории"></PageBadge>

  <div class="container">
    <div class="categories-list" v-if="loading">
      <CategoryInList v-for="n in 5" :key="n" :skeleton="true" :data="{name:''}"></CategoryInList>
    </div>
    <div class="categories-list" v-if="!loading">
      <CategoryInList @click="categorySelected(n._id)" v-for="n in categories" :key="n" :data="n"></CategoryInList>
    </div>

  </div>
  <Navigation></Navigation>
</template>

<style scoped>

.categories-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
</style>